import React, { useState } from 'react';
import {
  Box,
  CssBaseline,
  Typography,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  Switch,
  Toolbar,
} from '@mui/material';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

const drawerWidth = 300;

const initialStocks = [
  { id: 1, name: 'エクソソーム１', quantity: 100, receivedDate: '2024-07-01', status: 'In Stock' },
  { id: 2, name: 'エクソソーム２', quantity: 50, receivedDate: '2024-07-05', status: 'Low Stock' },
  { id: 3, name: 'エクソソーム３', quantity: 200, receivedDate: '2024-07-10', status: 'In Stock' },
  // Additional stock data
];

const initialReceipts = [
  { id: 1, name: 'エクソソーム１', quantity: 50, receivedDate: '2024-07-01', receivedBy: '山田太郎', confirmed: false },
  { id: 2, name: 'エクソソーム２', quantity: 30, receivedDate: '2024-07-03', receivedBy: '鈴木次郎', confirmed: false },
  { id: 3, name: 'エクソソーム３', quantity: 70, receivedDate: '2024-07-05', receivedBy: '田中一郎', confirmed: false },
  { id: 4, name: 'エクソソーム4', quantity: 70, receivedDate: '2024-07-06', receivedBy: '田中一郎', confirmed: false },
  { id: 5, name: 'エクソソーム5', quantity: 80, receivedDate: '2024-07-07', receivedBy: '田中一郎', confirmed: false },
  { id: 6, name: 'エクソソーム6', quantity: 20, receivedDate: '2024-07-08', receivedBy: '田中一郎', confirmed: false },
  { id: 7, name: 'エクソソーム7', quantity: 40, receivedDate: '2024-07-09', receivedBy: '田中一郎', confirmed: false },
  { id: 8, name: 'エクソソーム8', quantity: 50, receivedDate: '2024-07-10', receivedBy: '田中一郎', confirmed: false },
  // Additional receipt data
];

const InventoryManagement: React.FC = () => {
  const [stocks] = useState(initialStocks);
  const [receipts, setReceipts] = useState(initialReceipts);

  const handleToggleConfirm = (id: number) => {
    setReceipts((prevReceipts) =>
      prevReceipts.map((receipt) =>
        receipt.id === id ? { ...receipt, confirmed: !receipt.confirmed } : receipt
      )
    );
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header />
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
        }}
      >
        <Toolbar />
        <Typography variant="h4" gutterBottom>在庫・受取管理</Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2, height: 500, overflowY: 'auto' }}>
              <Typography variant="h6" gutterBottom>在庫リスト</Typography>
              <List>
                {stocks.map((stock) => (
                  <React.Fragment key={stock.id}>
                    <ListItem>
                      <ListItemText
                        primary={`${stock.name} - 数量: ${stock.quantity}`}
                        secondary={`入庫日: ${stock.receivedDate} - ステータス: ${stock.status}`}
                      />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 2, height: 500, overflowY: 'auto' }}>
              <Typography variant="h6" gutterBottom>受取履歴</Typography>
              <List>
                {receipts.map((receipt) => (
                  <React.Fragment key={receipt.id}>
                    <ListItem>
                      <ListItemText
                        primary={`${receipt.name} - 数量: ${receipt.quantity}`}
                        secondary={`受取日: ${receipt.receivedDate} - 受取者: ${receipt.receivedBy}`}
                      />
                      <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="body2">受取スイッチ</Typography>
                        <Switch
                          checked={receipt.confirmed}
                          onChange={() => handleToggleConfirm(receipt.id)}
                          color="primary"
                        />
                      </Box>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default InventoryManagement;
