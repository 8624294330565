import React, { useState, useEffect } from 'react';
import { Box, CssBaseline, Toolbar, Grid, Paper, Typography, LinearProgress } from '@mui/material';
import { List, ListItem, ListItemText, Divider } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const drawerWidth = 0;

const data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  datasets: [
    {
      label: '月別販売数',
      data: [65, 59, 80, 81, 156, 55, 140, 70, 75, 60, 80, 90],
      borderColor: 'rgba(75,192,192,1)',
      backgroundColor: 'rgba(75,192,192,0.2)',
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'エクソソーム販売状況',
    },
  },
};

const Home: React.FC = () => {
  const [salesData, setSalesData] = useState([
    { name: 'A', location: '24/01/2025' },
    { name: 'B', location: '23/01/2025' },
    { name: 'C', location: '20/01/2025' },
    { name: 'B', location: '18/01/2025' },
    { name: 'B', location: '10/01/2025' },
    { name: 'C', location: '08/01/2025' },
    { name: 'A', location: '06/01/2025' },
    { name: 'B', location: '04/01/2025' },
    { name: 'C', location: '01/01/2025' },
  ]);

  useEffect(() => {
    // ここでDBからのデータをフェッチし、setSalesDataで更新する
    // 例: fetchSalesData();
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header />
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginLeft: `${drawerWidth}px`,
        }}
      >
        <Toolbar />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, height: '300px', display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" gutterBottom>Total sales</Typography>
              <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                <Bar data={data} options={options} style={{ maxHeight: '100%' }} />
              </Box>
            </Paper>
          </Grid>
          <Grid item container xs={12} md={4} direction="column" spacing={2}>
            <Grid item>
              <Paper sx={{ p: 2, backgroundColor: '#f3e5f5' }}>
                <Typography variant="h6">$15,000000000</Typography>
                <Typography variant="body1">＊月 販売数</Typography>
              </Paper>
            </Grid>
            <Grid item>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6">Top sales</Typography>
                <LinearProgress variant="determinate" value={50} />
                <Typography variant="body2">A clinic:</Typography>
                <LinearProgress variant="determinate" value={30} />
                <Typography variant="body2">B clinic:</Typography>
                <LinearProgress variant="determinate" value={20} />
                <Typography variant="body2">C clinic:</Typography>
                <LinearProgress variant="determinate" value={50} />
                <Typography variant="body2">D clinic:</Typography>
                <LinearProgress variant="determinate" value={30} />
                <Typography variant="body2">E clinic:</Typography>
                <LinearProgress variant="determinate" value={20} />
                <Typography variant="body2">F clinic:</Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper sx={{ height: '300px', display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>Order history</Typography>
              </Box>
              <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
                <List>
                  {salesData.map((sale, index) => (
                    <React.Fragment key={index}>
                      <ListItem>
                        <ListItemText primary={<span><strong>{sale.name}</strong> - {sale.location}</span>} />
                      </ListItem>
                      {index < salesData.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </List>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Home;
