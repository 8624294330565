import React, { useState } from 'react';
import { Box, CssBaseline, Typography, Grid, Paper, List, ListItem, ListItemText, Divider, Button, Toolbar, TextField, MenuItem } from '@mui/material';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

const drawerWidth = 300;

const invoices = [
  { id: 1, customer: 'John Doe', date: '2024-07-01', status: 'Unpaid' },
  { id: 2, customer: 'Jane Smith', date: '2024-07-02', status: 'Paid' },
  { id: 3, customer: 'Michael Johnson', date: '2024-07-03', status: 'Overdue' },
  // 追加の請求データ
];

const productOptions = [
  'エクソソーム１',
  'エクソソーム２',
  'エクソソーム３',
];

const BillingManagement: React.FC = () => {
  const [selectedInvoice, setSelectedInvoice] = useState<any>(null);
  const [formValues, setFormValues] = useState({
    productName: '',
    quantity: '',
    price: '',
  });

  const handleSelectInvoice = (invoice: any) => {
    setSelectedInvoice(invoice);
  };

  const handleDownloadPDF = () => {
    // PDFダウンロードロジックをここに追加
    const link = document.createElement('a');
    link.href = '/path/to/your/pdf'; // PDFのパスを指定
    link.download = 'invoice.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // フォーム送信ロジックをここに追加
    console.log('Form submitted:', formValues);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header />
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
        }}
      >
        <Toolbar />
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 2, maxHeight: '500px', overflow: 'auto' }}> {/* 高さを固定し、スクロール可能に */}
              <Typography variant="h6" gutterBottom>請求リスト</Typography>
              <List>
                {invoices.map((invoice) => (
                  <React.Fragment key={invoice.id}>
                    <ListItem button onClick={() => handleSelectInvoice(invoice)}>
                      <ListItemText primary={`Customer: ${invoice.customer}`} secondary={`Date: ${invoice.date} - Status: ${invoice.status}`} />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 2, height: '300px' }}> {/* 高さを請求リストと同じに設定 */}
              <Typography variant="h6" gutterBottom>請求詳細</Typography>
              {selectedInvoice ? (
                <Box>
                  <Typography variant="subtitle1">Customer: {selectedInvoice.customer}</Typography>
                  <Typography variant="subtitle1">Date: {selectedInvoice.date}</Typography>
                  <Typography variant="subtitle1">Status: {selectedInvoice.status}</Typography>
                  <Typography variant="subtitle1">Invoice ID: {selectedInvoice.id}</Typography>
                  {/* その他の請求詳細情報 */}
                  <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleDownloadPDF}>
                    請求書ダウンロード
                  </Button>
                </Box>
              ) : (
                <Typography variant="body1">請求を選択してください。</Typography>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, mt: 3 }}>
              <Typography variant="h6" gutterBottom>請求書作成</Typography>
              <Box component="form" onSubmit={handleSubmit}>
                <TextField
                  select
                  label="商品名"
                  name="productName"
                  value={formValues.productName}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                >
                  {productOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="数量"
                  name="quantity"
                  value={formValues.quantity}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="価格"
                  name="price"
                  value={formValues.price}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                  請求書作成
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BillingManagement;
