import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Users from '../pages/Users';
import Settings from '../pages/Settings';
import OrderManagement from '../pages/OrderManagement'; // 新しいコンポーネントをインポート
import InvoiceManagement from '../pages/InvoiceManagement'; // 新しいコンポーネントをインポート
import InventoryList from '../pages/InventoryList'; // 新しいコンポーネントをインポート

const Dashboard: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="users" element={<Users />} />
      <Route path="settings" element={<Settings />} />
      <Route path="order-management" element={<OrderManagement />} /> {/* 新しいルート */}
      <Route path="invoice-management" element={<InvoiceManagement />} /> {/* 新しいルート */}
      <Route path="InventoryList" element={<InventoryList />} /> {/* 新しいルート */}
    </Routes>
  );
};

export default Dashboard;
