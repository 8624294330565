import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Toolbar } from '@mui/material';
import { Home, Person, Settings, Inventory, Description } from '@mui/icons-material'; // アイコンをインポート
import { Link } from 'react-router-dom';

const drawerWidth = 300; // サイドメニューの幅を300に設定

const Sidebar: React.FC = () => {
  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
    >
      <Toolbar />
      <Divider />
      <List>
        <ListItem button component={Link} to="/dashboard">
          <ListItemIcon><Home /></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/dashboard/order-management">
          <ListItemIcon><Description /></ListItemIcon>
          <ListItemText primary="発注管理" />
        </ListItem>
        <ListItem button component={Link} to="/dashboard/invoice-management">
          <ListItemIcon><Description /></ListItemIcon>
          <ListItemText primary="請求管理" />
        </ListItem>
        <ListItem button component={Link} to="/dashboard/InventoryList">
          <ListItemIcon><Inventory /></ListItemIcon>
          <ListItemText primary="在庫・受取管理" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
