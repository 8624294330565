import React, { useState } from 'react';
import { Box, CssBaseline, Typography, Grid, Paper, List, ListItem, ListItemText, Divider, Button, Toolbar, TextField, MenuItem } from '@mui/material';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

const drawerWidth = 300;

const orders = [
  { id: 1, customer: 'エクソソーム１', date: '2024-07-01', status: 'Pending' },
  { id: 2, customer: 'エクソソーム２', date: '2024-07-02', status: 'Completed' },
  { id: 3, customer: 'エクソソーム３', date: '2024-07-03', status: 'Shipped' },
  // 追加のオーダーデータ
];

const productOptions = [
  'エクソソーム１',
  'エクソソーム２',
  'エクソソーム３',
];

const OrderManagement: React.FC = () => {
  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [formValues, setFormValues] = useState({
    productName: '',
    quantity: '',
    price: '',
  });

  const handleSelectOrder = (order: any) => {
    setSelectedOrder(order);
  };

  const handleDownloadPDF = () => {
    // PDFダウンロードロジックをここに追加
    const link = document.createElement('a');
    link.href = '/path/to/your/pdf'; // PDFのパスを指定
    link.download = 'order.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // フォーム送信ロジックをここに追加
    console.log('Form submitted:', formValues);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header />
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
        }}
      >
        <Toolbar />
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 2, maxHeight: '300px', overflow: 'auto' }}> {/* 高さを固定し、スクロール可能に */}
              <Typography variant="h6" gutterBottom>オーダーリスト</Typography>
              <List>
                {orders.map((order) => (
                  <React.Fragment key={order.id}>
                    <ListItem button onClick={() => handleSelectOrder(order)}>
                      <ListItemText primary={`Order: ${order.customer}`} secondary={`Date: ${order.date} - Status: ${order.status}`} />
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 2, height: '300px' }}> {/* 高さをオーダーリストと同じに設定 */}
              <Typography variant="h6" gutterBottom>オーダー詳細</Typography>
              {selectedOrder ? (
                <Box>
                  <Typography variant="subtitle1">Customer: {selectedOrder.customer}</Typography>
                  <Typography variant="subtitle1">Date: {selectedOrder.date}</Typography>
                  <Typography variant="subtitle1">Status: {selectedOrder.status}</Typography>
                  <Typography variant="subtitle1">Order ID: {selectedOrder.id}</Typography>
                  {/* その他のオーダー詳細情報 */}
                  <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleDownloadPDF}>
                    発注書ダウンロード
                  </Button>
                </Box>
              ) : (
                <Typography variant="body1">オーダーを選択してください。</Typography>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, mt: 3 }}>
              <Typography variant="h6" gutterBottom>発注書作成</Typography>
              <Box component="form" onSubmit={handleSubmit}>
                <TextField
                  select
                  label="商品名"
                  name="productName"
                  value={formValues.productName}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                >
                  {productOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="数量"
                  name="quantity"
                  value={formValues.quantity}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="価格"
                  name="price"
                  value={formValues.price}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                  発注書作成
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default OrderManagement;
